import get from "lodash/get";

export const selectGraphFilter = ({ marktdaten }) =>
  get(marktdaten, "graphFilter", {});

export const selectMarktdaten = ({ marktdaten }) => marktdaten;

export const selectGraphData = ({ marktdaten }) => marktdaten.graphData;

export const selectMarktdatenName = ({ marktdaten }) => get(marktdaten, "name");
