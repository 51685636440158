import moment from "moment";
import each from "lodash/each";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import reduce from "lodash/reduce";

import { FILTER_VALUES } from "../marktdaten/Filter";

/**
 * QUERY DATA FOR PRICE CHART
 */
export const getQueryChartData = ({
  page = 1,
  pageSize = 10000,
  period,
  material,
  filterValue,
  tableName,
}) => {
  let parameters = getDayByPeriod(period, filterValue);

  return `query homeInfo {
          detail(page: ${page}, pageSize: ${pageSize}, tableName: "${tableName}", valueType: "${material}" , ${parameters}) {
            totalPages
            totalRecords
            data {
              id
              date
              rate
              settlement
              stock
              year
              month
              price
            }
          
        }
      }
      `;
};

const getDateFormatByPeriod = (date, period) => {
  if (period === -1) {
    return "YYYY";
  }

  return "MMM";
};

export const getUnit = ({ tableName }) => {
  if (tableName === "german_metal_price") {
    return ["€"];
  }

  if (tableName === "exchange_rates") {
    return ["$/€"];
  }

  return ["$", "t"];
};

export const getLegendName = ({ t, tableName, name }) => {
  if (tableName === "german_metal_price") {
    return [t(name)];
  }

  if (tableName === "exchange_rates") {
    return [t(name)];
  }

  return [t(`LME ${name} Cash-Settlement`), t(`LME ${name}-Bestände`)];
};

export const getChartDomain = ({ tableName }) => {
  if (["german_metal_price", "exchange_rates"].includes(tableName)) {
    return ["dataMin", "dataMax"];
  }

  return ["dataMin - 500", "dataMax + 500"];
};

export const getChartData = ({ data, period, tableName, domain }) => {
  if (!data) return [];
  const DATE_FORMAT = "dd-MM-YYYY";
  const withCategories = each(data, function(el) {
    el.cate = moment(el.date, DATE_FORMAT).format("M[-]YYYY");
    el.dateObj = moment(el.date, DATE_FORMAT);
  });

  const sortList = withCategories.sort(function compare(a, b) {
    return a.dateObj.isAfter(b.dateObj) ? 1 : -1;
  });

  const groupResult = groupBy(sortList, "date");

  return map(groupResult, el => {
    let name = "";
    if (el[0]) {
      const date = el[0].dateObj;
      var languageSymbol = domain === "en" ? "en-EN" : "de-DE";
      let intl = new Intl.DateTimeFormat(languageSymbol, {
        month: "short",
        year: "numeric",
      });
      let dte = intl.formatToParts(date);

      name =
        getDateFormatByPeriod(date, period) === -1
          ? dte.find(d => d.type === "year").value
          : dte.find(d => d.type === "month").value;
      // name = date.format(getDateFormatByPeriod(date, period));
    }

    if (tableName === "german_metal_price") {
      return {
        name,
        date: el[0].date,
        firstArray: reduce(map(el, "price"), (memo, num) => memo + num, 0),
      };
    }

    if (tableName === "exchange_rates") {
      return {
        name,
        date: el[0].date,
        firstArray: reduce(map(el, "rate"), (memo, num) => memo + num, 0),
      };
    }

    return {
      name,
      date: el[0].date,
      firstArray: reduce(map(el, "settlement"), (memo, num) => memo + num, 0),
      secondArray: reduce(map(el, "stock"), (memo, num) => memo + num, 0),
    };
  });
};

export const getDayByPeriod = (period, filterValue) => {
  const DATE_FORMAT = "MM-DD-YYYY";
  const currentDate = moment().format(DATE_FORMAT);

  switch (period) {
    case FILTER_VALUES.THREE_MONTH: {
      const previousMonth = moment()
        .subtract(3, "months")
        .format(DATE_FORMAT);

      return `startDate:"${previousMonth}", endDate:"${currentDate}"`;
    }
    case FILTER_VALUES.SIX_MONTH: {
      const previousMonth = moment()
        .subtract(6, "months")
        .format(DATE_FORMAT);

      return `startDate:"${previousMonth}", endDate:"${currentDate}"`;
    }
    case FILTER_VALUES.ONE_YEAR: {
      const previousMonth = moment()
        .subtract(12, "months")
        .format(DATE_FORMAT);

      return `startDate:"${previousMonth}", endDate:"${currentDate}"`;
    }
    case FILTER_VALUES.ALL: {
      const previousMonth = moment()
        .year(2007)
        .format(DATE_FORMAT);
      return `startDate:"${previousMonth}", endDate:"${currentDate}"`;
    }
    case FILTER_VALUES.CUSTOM: {
      const startDate = moment(filterValue[0]).format(DATE_FORMAT);
      const endDate = moment(filterValue[1]).format(DATE_FORMAT);
      return `startDate:"${startDate}", endDate:"${endDate}"`;
    }
    default: {
      const previousMonth = moment()
        .subtract(12, "months")
        .format(DATE_FORMAT);

      return `startDate:"${previousMonth}", endDate:"${currentDate}"`;
    }
  }
};

export const numberWithCommas = (domain = "en") => x => {
  if (!x) return "";
  return new Intl.NumberFormat(domain === "en" ? "en" : "de").format(x);
};
