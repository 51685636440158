import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import DatePicker from "../marktdaten-tables/DatePicker";
import { setFilter } from "../../action/MarktdatenActions";
import {
  selectGraphFilter,
  selectMarktdaten,
} from "../../reducer/MarktdatenSelector";

import styles from "./MarktdatenInfoSection.module.scss";
import filterStyles from "./Filter.module.scss";
import { getLegendName } from "./utils";
import { withNamespaces } from "react-i18next";

export const FILTER_VALUES = {
  ALL: -1,
  THREE_MONTH: "quarter",
  SIX_MONTH: "semester",
  ONE_YEAR: "year",
  CUSTOM: "custom",
};

const FILTER_OPTIONS = [
  {
    label: "3M",
    value: FILTER_VALUES.THREE_MONTH,
  },
  {
    label: "6M",
    value: FILTER_VALUES.SIX_MONTH,
  },
  {
    label: "1J",
    value: FILTER_VALUES.ONE_YEAR,
  },
  {
    label: "Alle",
    value: FILTER_VALUES.ALL,
  },
];

const Filter = props => {
  const { intialDisplay, tableName, t } = props;
  /**
   * STORE
   */
  const dispatch = useDispatch();
  const chartData = useSelector(selectMarktdaten);
  const { type: filterType } = useSelector(selectGraphFilter);

  /**
   * HANDLER
   */
  const changeFilter = useCallback(
    ({ type }) => {
      dispatch(setFilter({ type, value: [] }));
    },
    [dispatch]
  );

  const changeDate = useCallback(
    range => {
      dispatch(setFilter({ type: FILTER_VALUES.CUSTOM, value: range }));
    },
    [dispatch]
  );

  const [firstLegend, secondLegend] = getLegendName({
    t,
    tableName,
    name: chartData.name,
  });
  return (
    <div
      className={`${styles.filters} ${
        intialDisplay === "graph" ? styles.graph : styles.table
      } `}
    >
      {intialDisplay === "graph" && (
        <div className={styles.lineNameContainer}>
          <div className={styles.lineName}>
            <div className={styles.blockColor} />{" "}
            <span className={filterStyles.graphLineName}>- {firstLegend}</span>
          </div>
          {!!secondLegend && (
            <div className={styles.lineName}>
              <div className={styles.blockColor2} />{" "}
              <span className={filterStyles.graphLineName}>
                - {secondLegend}
              </span>
            </div>
          )}
        </div>
      )}

      <div className={styles.filterOptions}>
        <div className={styles.groupFilter}>
          {FILTER_OPTIONS.map(option => (
            <span
              className={`${styles.dateBubble} ${
                filterType === option.value ? styles.isActive : ""
              }`}
              onClick={() => {
                if (filterType !== option.value)
                  changeFilter({
                    type: option.value,
                  });
              }}
            >
              {t(option.label)}
            </span>
          ))}
        </div>
        <div className={styles.calendarFilter}>
          <DatePicker
            changeDateRange={changeDate}
            lightCalendar={filterType === FILTER_VALUES.CUSTOM}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(withNamespaces()(Filter));
