/* eslint-disable indent */
import get from "lodash/get";

import {
  CHANGE_INITIAL_DISPLAY,
  SET_MATERIAL_DATA,
  SET_NAME,
  SET_GRAPH_FILTER,
  UPDATE_PRICE_CHART,
} from "../action/types";

import { FILTER_VALUES } from "../components/marktdaten/Filter";

const INITIAL_STATE = {
  intialDisplay: "table",
  name: "test",
  MaterialList: [],
  MaterialData: [],
  graphFilter: {
    type: FILTER_VALUES.THREE_MONTH,
  },
  graphData: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_INITIAL_DISPLAY:
      return { ...state, intialDisplay: action.payload };
    case SET_MATERIAL_DATA:
      return { ...state, MaterialData: action.payload };
    case SET_NAME:
      return { ...state, name: action.payload };
    case SET_GRAPH_FILTER:
      return { ...state, graphFilter: { ...action.payload } };
    case UPDATE_PRICE_CHART: {
      return {
        ...state,
        graphData: get(action, "payload", []),
      };
    }
    default:
      return state;
  }
};
