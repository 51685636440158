// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-ablauf-index-js": () => import("./../../../src/pages/ablauf/index.js" /* webpackChunkName: "component---src-pages-ablauf-index-js" */),
  "component---src-pages-agb-index-js": () => import("./../../../src/pages/agb/index.js" /* webpackChunkName: "component---src-pages-agb-index-js" */),
  "component---src-pages-altmetall-kaufen-index-js": () => import("./../../../src/pages/altmetall-kaufen/index.js" /* webpackChunkName: "component---src-pages-altmetall-kaufen-index-js" */),
  "component---src-pages-aluminiumpreis-index-js": () => import("./../../../src/pages/aluminiumpreis/index.js" /* webpackChunkName: "component---src-pages-aluminiumpreis-index-js" */),
  "component---src-pages-anfrage-index-js": () => import("./../../../src/pages/anfrage/index.js" /* webpackChunkName: "component---src-pages-anfrage-index-js" */),
  "component---src-pages-batteriepreise-index-js": () => import("./../../../src/pages/batteriepreise/index.js" /* webpackChunkName: "component---src-pages-batteriepreise-index-js" */),
  "component---src-pages-bleipreise-index-js": () => import("./../../../src/pages/bleipreise/index.js" /* webpackChunkName: "component---src-pages-bleipreise-index-js" */),
  "component---src-pages-disclaimer-index-js": () => import("./../../../src/pages/disclaimer/index.js" /* webpackChunkName: "component---src-pages-disclaimer-index-js" */),
  "component---src-pages-edelstahlpreis-index-js": () => import("./../../../src/pages/edelstahlpreis/index.js" /* webpackChunkName: "component---src-pages-edelstahlpreis-index-js" */),
  "component---src-pages-eisenpreise-index-js": () => import("./../../../src/pages/eisenpreise/index.js" /* webpackChunkName: "component---src-pages-eisenpreise-index-js" */),
  "component---src-pages-elektroschrottpreise-index-js": () => import("./../../../src/pages/elektroschrottpreise/index.js" /* webpackChunkName: "component---src-pages-elektroschrottpreise-index-js" */),
  "component---src-pages-hartmetallpreis-index-js": () => import("./../../../src/pages/hartmetallpreis/index.js" /* webpackChunkName: "component---src-pages-hartmetallpreis-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-impressum-index-js": () => import("./../../../src/pages/impressum/index.js" /* webpackChunkName: "component---src-pages-impressum-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kabelpreise-index-js": () => import("./../../../src/pages/kabelpreise/index.js" /* webpackChunkName: "component---src-pages-kabelpreise-index-js" */),
  "component---src-pages-kupferpreis-index-js": () => import("./../../../src/pages/kupferpreis/index.js" /* webpackChunkName: "component---src-pages-kupferpreis-index-js" */),
  "component---src-pages-legierungenpreis-index-js": () => import("./../../../src/pages/legierungenpreis/index.js" /* webpackChunkName: "component---src-pages-legierungenpreis-index-js" */),
  "component---src-pages-marktdaten-disclaimer-js": () => import("./../../../src/pages/marktdaten/disclaimer.js" /* webpackChunkName: "component---src-pages-marktdaten-disclaimer-js" */),
  "component---src-pages-marktdaten-index-js": () => import("./../../../src/pages/marktdaten/index.js" /* webpackChunkName: "component---src-pages-marktdaten-index-js" */),
  "component---src-pages-marktdaten-material-js": () => import("./../../../src/pages/marktdaten/material.js" /* webpackChunkName: "component---src-pages-marktdaten-material-js" */),
  "component---src-pages-messingpreise-index-js": () => import("./../../../src/pages/messingpreise/index.js" /* webpackChunkName: "component---src-pages-messingpreise-index-js" */),
  "component---src-pages-partner-index-js": () => import("./../../../src/pages/partner/index.js" /* webpackChunkName: "component---src-pages-partner-index-js" */),
  "component---src-pages-pro-index-js": () => import("./../../../src/pages/pro/index.js" /* webpackChunkName: "component---src-pages-pro-index-js" */),
  "component---src-pages-redux-wrapper-js": () => import("./../../../src/pages/ReduxWrapper.js" /* webpackChunkName: "component---src-pages-redux-wrapper-js" */),
  "component---src-pages-schrottpreise-index-js": () => import("./../../../src/pages/schrottpreise/index.js" /* webpackChunkName: "component---src-pages-schrottpreise-index-js" */),
  "component---src-pages-sitemap-index-js": () => import("./../../../src/pages/sitemap/index.js" /* webpackChunkName: "component---src-pages-sitemap-index-js" */),
  "component---src-pages-stahlpreise-index-js": () => import("./../../../src/pages/stahlpreise/index.js" /* webpackChunkName: "component---src-pages-stahlpreise-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */),
  "component---src-pages-ueber-uns-index-js": () => import("./../../../src/pages/ueber-uns/index.js" /* webpackChunkName: "component---src-pages-ueber-uns-index-js" */),
  "component---src-pages-zinkpreis-index-js": () => import("./../../../src/pages/zinkpreis/index.js" /* webpackChunkName: "component---src-pages-zinkpreis-index-js" */),
  "component---src-pages-zinnpreis-index-js": () => import("./../../../src/pages/zinnpreis/index.js" /* webpackChunkName: "component---src-pages-zinnpreis-index-js" */),
  "component---src-templates-marktdaten-index-js": () => import("./../../../src/templates/marktdaten/index.js" /* webpackChunkName: "component---src-templates-marktdaten-index-js" */)
}

