/* eslint-disable prettier/prettier */
import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../reducer";
import ReduxThunk from "redux-thunk";
import { DomainProvider } from "../context/DomainContext";
import { PrefixProvider } from "../context/PrefixContext";
import i18n from "../i18n/i18n";

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const createReduxStore = () =>
  createStore(reducers, composeEnhancers(applyMiddleware(ReduxThunk)));

const isBrowser = typeof window !== "undefined";
let domain = process.env.GATSBY_DOMAIN || "ch";


if (isBrowser) {
  const validSubdomains = ["at", "de", "ch"];

  let currentDomain = window.location.origin.split(".").pop();

  if (validSubdomains.includes(currentDomain)) domain = currentDomain;

  if (window.location.origin.indexOf("en.schrott") > -1) domain = "en";
  if (domain === "en") {
    i18n.changeLanguage("en");
  }
} else {
  domain = process.env.GATSBY_DOMAIN;
}

export default ({ element }) => (
  <Provider store={createReduxStore()}>
 
    <DomainProvider value={domain}><PrefixProvider value={process.env.GATSBY_DEPLOYFOLDER}>{element}</PrefixProvider></DomainProvider>
  </Provider>
);
