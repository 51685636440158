import React, { useState,useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import DateRangePicker from "@material-ui/lab/MobileDateRangePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import "./filterStyles.css";
import styles from "./DatePicker.module.scss";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { withNamespaces } from "react-i18next";
import DomainContext from "../../context/DomainContext";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
const BasicDateRangePicker = (props) => {
  const [value, setValue] = useState([null, null]);

  const [openCalendar, setOpenCalendar] = useState(false);

  const domain = useContext(DomainContext);
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogActions-root": {
        background: "red!important",
      },
      "& .MuiDateRangePickerDay-rangeIntervalDayHighlight	": {
        color: "blue",
        background: "rgba(240, 166, 14, 0.15)",
      },
      "& .MuiDateRangePickerDay-day.Mui-selected": {
        background: "#F0A60E",
      },
      
    },
  });
  const classes = useStyles();
  const { changeDateRange, lightCalendar,t } = props;
  return (
    <div className={styles.calendarContainer} >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={domain==="en"?enLocale:deLocale}>
        <DateRangePicker
          className={`${classes.root} ${
            lightCalendar ? null : classes.disabledCalendar
          }  `}
          calendars={1} 
          open={openCalendar}
          value={value} 
          clear={true}
          cancelText={t("Stornieren")}
          showToolbar={false}
          onAccept={newValue => changeDateRange(newValue)}
          onClose={() => setOpenCalendar(false)}
          onChange={newValue => {}}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <CalendarTodayIcon
                className={`${styles.calendarIcon} ${
                  lightCalendar ? styles.active : null
                }`}
                onClick={() => {
                  if (lightCalendar === false) {
                    setValue([null, null]);
                  }

                  setOpenCalendar(true);
                }}
              />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
export default  withNamespaces()(BasicDateRangePicker);