import axios from "axios";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

import { getDetatil } from "../services/graphqlQueries";
import {
  CHANGE_INITIAL_DISPLAY,
  SET_MATERIAL_DATA,
  SET_NAME,
  UPDATE_PRICE_CHART,
  SET_GRAPH_FILTER,
} from "./types";

const SERVER_URL =
  "https://2xerhhcoxf.execute-api.eu-central-1.amazonaws.com/dev";

export const filterGraphData = ({ material, period }) => async dispatch => {
  const data = await getDetatil(material, period);
  dispatch({ type: SET_MATERIAL_DATA, payload: data });
};

export const setFilter = payload => ({
  type: SET_GRAPH_FILTER,
  payload,
});

export const changeInitalDisplay = value => dispatch => {
  dispatch({ type: CHANGE_INITIAL_DISPLAY, payload: value });
};

export const setMaterialData = value => dispatch => {
  dispatch({ type: SET_MATERIAL_DATA, payload: value });
};

export const setName = value => dispatch => {
  dispatch({ type: SET_NAME, payload: value });
};

export const fetchPriceChartData = query => {
  const url = SERVER_URL + "/graphql";
  return dispatch => {
    axios
      .post(url, JSON.stringify({ query }))
      .then(({ data }) => {
        dispatch({
          type: UPDATE_PRICE_CHART,
          payload: sortBy(get(data, "data.detail.data"), ["date"]).reverse(),
        });
      })
      .catch(err => console.log(err));
  };
};
